<template>
  <app-btn
    :text="text"
    :top="top"
    :right="right"
    :left="left"
    :color-tooltip="colorTooltip"
    :access="access"
    :bottom="bottom"
    :disabled="isDisabled"
    :show-custom-tooltip-title="showCustomTooltipTitle"
    :show-tooltip="showTooltip">
    <template #buttonWrapper="{ btnListeners}">
      <div
        :class="[wrapperClass, isDisabled ? 'c-grey' : '']"
        :style="wrapperStyle"
        v-on="mergeAttrs($listeners, btnListeners)">
        <slot name="img">
          <v-icon
            v-if="isIcon"
            :class="btnClass"
            :color="iconColor"
            :size="iconHeight">
            {{ iconName }}
          </v-icon>
          <img
            v-else
            :class="btnClass"
            :width="iconWidth"
            :height="iconHeight"
            :src="iconName">
        </slot>
        <slot />
      </div>
    </template>
  </app-btn>
</template>
<script>
import appBtnMixin from '@/mixins/appBtnMixin';
export default {
  name: 'AppIconBtn',
  mixins: [appBtnMixin],
  inheritAttrs: false,
  props: {
    iconName: {
      type: String,
      default: '',
    },
    isIcon: {
      type: Boolean,
      default: true,
    },
    iconHeight: {
      type: [String, Number],
      default: '15px',
    },
    iconWidth: {
      type: [String, Number],
      default: '15px',
    },
    iconColor: {
      type: String,
      default: undefined,
    },
    wrapperStyle: {
      type: Object,
      default: null,
    },
    wrapperClass: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped lang="scss">
.c-grey {
  cursor: context-menu !important;
  color: grey;
}
</style>
